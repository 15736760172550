<template>
  <v-app>
    <div class="login-frame">
      <div class="login-form">
        <v-overlay :value="$store.state.overlay.show" :absolute="false">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <div class="login-form">
          <router-link to="/" class="links">

            <v-img max-height="64" max-width="172" class="logo"
                   v-bind:src="require('@/assets/images/Leita_blue.png')">
            </v-img>
          </router-link>
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
              @change="changeColorByObserver()"
          >
            <v-form
                ref="form"
                @submit.prevent="login($data)"
            >
              <label for="email" style="padding-bottom: 12px;">E-mail</label>
              <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    required
                    class="v-text-field-custom"
                    tile
                    solo
                    placeholder="myemail@gmail.com"
                ></v-text-field>
              </validation-provider>

              <label for="password" style="padding-bottom: 12px;">Password</label>
              <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
              >
                <v-text-field
                    v-model="password"
                    required
                    class="v-text-field-custom"
                    tile
                    :error-messages="errors"
                    :type="'password'"
                    solo
                    placeholder="******"
                ></v-text-field>
              </validation-provider>

              <v-col style="padding: 0px; padding-bottom: 20px;">
                <v-btn
                    tile
                    :color="submitDisableButtonColor"
                    style="width: 100%;"
                    x-large
                    :disabled="invalid"
                    type="submit"
                >
                  <span class="login-button-text"> Login</span>
                </v-btn>
              </v-col>
            </v-form>
          </validation-observer>
          <v-container>
            <v-row style="padding-bottom: 8px;">
              <v-col cols="8" style="padding-left: 0px;">
                <router-link to="/recovery-password" class="links">Forgot your password</router-link>
              </v-col>
              <v-col cols="4" style="padding-right: 0px; text-align: right;">
                <router-link to="/registrate" class="links">Register</router-link>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" style="padding-left: 0px;">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="2" style="padding: 0px; padding-left:15px;">or</v-col>
              <v-col cols="5" style="padding-left: 0px; padding-right: 0px;">
                <v-divider></v-divider>
              </v-col>

            </v-row>

            <v-row style="padding-bottom: 20px; padding-top: 20px;">
              <v-btn
                  class="ma-2"
                  outlined
                  rounded
                  color="#206297"
                  style="width: 100%;"
                  large
                  @click="AuthProvider('google')"
              >
                <v-img height="20px" max-width="20px" v-bind:src="require('@/assets/images/google-icon.png')"
                       width="15px">
                </v-img>
                <span class="social-button-text"> Sign in with Google</span>


              </v-btn>

            </v-row>

          </v-container>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Footer from "@/components/home/Footer";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules';
import LoginSubmitButtonMixin from "@/mixins/LoginSubmitButtonMixin";


extend('email', {
  ...email,
  message: 'Email must be valid',
});

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required'
});


export default {
  name: "Login",
  mixins: [LoginSubmitButtonMixin],
  components: {
    Footer,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    email: '',
    password: '',
    loginForm: {
      username: '',
      password: '',
    },
    prevRoute: null,
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      switch (from.path) {
        case '/search':
          vm.prevRoute = from;
          break;
        default:
          vm.prevRoute = '/';
      }
    })
  },
  methods: {
    error() {
      this.$notify({
        type: 'error',
        group: 'leita',
        duration: 10000,
        title: 'An error has occurred',
        text: 'Refresh the page and try again!'
      });
    },
    login(payload) {
      if (this.$refs.observer.validate()) {
        this.$store.dispatch('overlay/show');
        this.loginForm.username = payload.email;
        this.loginForm.password = payload.password;

        this.$store.dispatch('authentication/login', this.loginForm).then(
            user => {
              if (user) {
                this.$user.set({role: user.role});
                this.$router.push(this.prevRoute);
              }
            },
            error => {
              if (error.response.status === 401) {
                this.$refs.observer.setErrors({password: error.response.data.message});

              } else {
                this.error();
              }

              // console.log(error.response);
            })
      }


    },
    AuthProvider(provider) {
      // var self = this
      this.$store.dispatch('overlay/show');

      this.$auth.authenticate(provider).then(response => {
        this.$store.dispatch('authentication/socialLogin', {provider: provider, data: response}).then(
            responce => {


              if (responce['status'] === 200) {
                var user = responce['data']['user'];
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('saved_articles', JSON.stringify(responce['data']['saved_articles']));
                this.$user.set({role: user.role});
                this.$router.push(this.prevRoute);

              }

              if (responce['status'] === 201) {
                this.$router.push({name: 'CheckEmail', params: {type: 'registration'}});

              }


              // if (user) {
              //
              //   this.$user.set({role: user.role});
              //   this.$router.push(this.prevRoute);
              // }
            },
            error => {
              if (error.response.status === 401) {
                this.$refs.observer.setErrors({password: error.response.data.message});

              } else {
                this.error();
              }


              // console.log(error.response);
            }).finally(() => this.$store.dispatch('overlay/hide', "", {root: true}))

      }).catch(() => {
        this.$store.dispatch('overlay/hide', "", {root: true})
        this.error();
      })

    },

    SocialLogin(provider, response) {

      this.$http.post('/sociallogin/' + provider, response).then(response => {

        console.log(response.data)

      }).catch(err => {
        console.log({err: err})
      })
    },

  },


}
</script>

<style scoped src="@/assets/css/login.css">
</style>
